import ResourcesPage from './resources'
import SpecificResourcePage from './resourcepages'
import {useState, useEffect} from 'react'
import { firestore } from '../firebase.js';
import { collection, getDocs } from 'firebase/firestore';
import {Route, Routes} from 'react-router-dom';

function ResourceTab () {
    const [resources, setResources] = useState([]);
    const fetchData = async () => {
      const resourcesRef = collection(firestore, "resources");
      const resourcesSnapshot = await getDocs(resourcesRef);
      setResources(resourcesSnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
    }
    
    useEffect(() => {
      fetchData();
    }, []);

    return (
        <Routes>
            <Route path="resources" element={<ResourcesPage resources={resources}/>} />
            {resources.map((location) => <Route key={location.id} path={location.name} element={<SpecificResourcePage resource={location} />}/>)}
        </Routes>
    )
}

export default ResourceTab