import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Background = styled(Box)({
  minHeight: 'calc(100vh - 64px)',
  width: '100%',
  background: '#202A44', // navy blue
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Contact = () => {
  return (
  <Background>
      <Container>
      <Typography variant="h4" component="h1" gutterBottom align="center" color='green'>
          Contact Me
      </Typography>
      </Container>
  </Background>
  );
};


export default Contact;
