import React, { useState } from 'react';
import { styled, AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import lthcLogo from "../images/lthcLogo.png";
import { isMobile } from 'react-device-detect';

const StyledToolbar = styled(Toolbar)({
  boxShadow: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  padding: 0,
});

const NavBarButton = styled(Button)({
  fontSize: "18px",
  color: "black",
  boxShadow: "none",
  textTransform: "none",
  margin: "10px",
  "&:hover": {
    backgroundColor: "inherit",
    boxShadow: "none",
  },
});


const NavToolbar = ({ isAuthenticated }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/resourcestab/resources">
          <ListItemText primary="Resources" />
        </ListItem>
        {!isAuthenticated ? (
          null
        ) : (
          <ListItem button component={Link} to="/resource-form">
            <ListItemText primary="Add/Update Resource" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar color="white" position="static" sx={{ height: "15vh", boxShadow: "none", padding: 0 }}>
      <StyledToolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: isMobile ? 0 : "4vw" }}>
          <NavBarButton component={Link} to="/" disableRipple><img src={lthcLogo} height="100px" alt="logo" /></NavBarButton>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center", gap: 2, paddingRight: "5vw" }}>
          <NavBarButton component={Link} to="/" disableRipple>Home</NavBarButton>
          <NavBarButton component={Link} to="/resourcestab/resources" disableRipple>Resources</NavBarButton>
          {!isAuthenticated ? (
            null
          ) : (
            <NavBarButton component={Link} to="/resource-form" disableRipple>Add/Update Resource</NavBarButton>
          )}
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'block', md: 'none' }, marginRight: '16px' }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {drawerList}
        </Drawer>
      </StyledToolbar>
    </AppBar>
  );
};

export default NavToolbar;
