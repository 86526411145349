// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  doc,
  getDoc
} from 'firebase/firestore';

import {
  getAuth,
  onAuthStateChanged,
  signInWithRedirect,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence
} from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsn3FCwYt8x_19pKOvCRrxSNPohPNp2ok",
  authDomain: "lthc-web.firebaseapp.com",
  projectId: "lthc-web",
  storageBucket: "lthc-web.appspot.com",
  messagingSenderId: "1018509370599",
  appId: "1:1018509370599:web:26531f58ae7492f8bc6e42"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export {
  firestore,
  app,
  collection,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  doc,
  getDoc,
  onAuthStateChanged,
  signInWithRedirect,
  signInWithEmailAndPassword,
  getAuth,
  setPersistence,
  browserSessionPersistence
}
