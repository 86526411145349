import { createTheme, ThemeProvider } from "@mui/material";

function Theme({children}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#486284",
        light: "#6c819c",
        dark: "#32445c",
      },
      secondary: {
        main: '#ecb81c',
        light: '#E9DB5D',
        dark: '#A29415',
        contrastText: '#242105',
      },
    },
  }); 
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default Theme;