import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Select, MenuItem, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { doc, getDoc, setDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';

import { firestore } from '../firebase';

const ResourceForm = () => {
  const [mode, setMode] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [resources, setResources] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    openTimes: Array(7).fill("Closed"),
    overview: '',
    services: '',
    type: '',
    email: '',
    phone: '',
    website: '',
  });
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const types = ["Food Pantry", "Shelter", "Medical Resources", "Other"];

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'resources'));
        const resourceList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourceList);
      } catch (error) {
        console.error("Error fetching resources: ", error);
      }
    };

    fetchResources();
  }, []);

  useEffect(() => {
    const fetchResource = async () => {
      if (mode === 'edit' && resourceId) {
        try {
          const resourceRef = doc(firestore, 'resources', resourceId);
          const resourceSnap = await getDoc(resourceRef);

          if (resourceSnap.exists()) {
            setFormData(resourceSnap.data());
          } else {
            alert("No such resource found!");
          }
        } catch (error) {
          console.error("Error fetching resource: ", error);
        }
      }
    };

    fetchResource();
  }, [mode, resourceId]);

  const handleModeChange = (event) => {
    setMode(event.target.value);
    setFormData({
      name: '',
      address: '',
      openTimes: Array(7).fill("Closed"),
      overview: '',
      services: '',
      type: '',
      email: '',
      phone: '',
      website: '',
    });
    setResourceId('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const formatTime = (time24) => {
    if (time24 === "Closed" || time24 === "") return "Closed";
    const [hour, minute] = time24.split(':').map(Number);
    const ampm = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
  };

  const parseTime = (time12) => {
    if (time12 === "" || time12 === "Closed") return "";
    if(time12 === "00:00") return time12;
    const [time, modifier] = time12.split(' ');
    let [hour, minute] = time.split(':').map(Number);
    
    if (modifier.toLowerCase() === 'pm' && hour !== 12) {
        hour += 12;
    } else if (modifier.toLowerCase() === 'am' && hour === 12) {
        hour = 0;
    }
    let time24 = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    console.log(time24);
    return time24 ;
  };

  const handleOpenTimesChange = (index, startOrEnd, value) => {
    const updatedOpenTimes = [...formData.openTimes];
    if (updatedOpenTimes[index] === "Closed") {
      updatedOpenTimes[index] = "00:00 - 00:00";
    }

    const [start, end] = updatedOpenTimes[index].split(' - ');
    updatedOpenTimes[index] = startOrEnd === 'start' ? `${formatTime(value)} - ${end}` : `${start} - ${formatTime(value)}`;
    setFormData((prevData) => ({ ...prevData, openTimes: updatedOpenTimes }));
    console.log(formData.openTimes);
    console.log(updatedOpenTimes);
  };

  const handleClosedChange = (index, isClosed) => {
    const updatedOpenTimes = [...formData.openTimes];
    updatedOpenTimes[index] = isClosed ? "Closed" : "00:00 - 00:00";
    setFormData((prevData) => ({ ...prevData, openTimes: updatedOpenTimes }));
  };

  const camelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => index === 0 ? match.toLowerCase() : match.toUpperCase())
      .replace(/\s+/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedOpenTimes = formData.openTimes.map(time => {
      if (time === "Closed") return "Closed";
      const [start, end] = time.split(' - ');
      return start === "00:00" && end === "00:00"
        ? "Closed"
        : `${start} - ${end}`;
    });

    const dataToSave = { ...formData, openTimes: formattedOpenTimes };
    const resourceDocId = camelCase(formData.name); // Use the camel case resource name as the ID

    try {
      if (mode === 'edit') {
        await setDoc(doc(firestore, "resources", resourceId), dataToSave);
        alert("Resource updated successfully!");
      } else {
        await setDoc(doc(firestore, "resources", resourceDocId), dataToSave); // Use the camel case ID
        alert("Resource added successfully!");
      }

      setFormData({
        name: '',
        address: '',
        openTimes: Array(7).fill("Closed"),
        overview: '',
        services: '',
        type: '',
        email: '',
        phone: '',
        website: '',

      });
      setMode('');
      setResourceId('');
    } catch (error) {
      console.error("Error saving resource: ", error);
      alert("Failed to save resource. Please try again.");
    }
  };
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      try {
        await deleteDoc(doc(firestore, 'resources', resourceId));
        alert("Resource deleted successfully!");
        setFormData({
          name: '',
          address: '',
          openTimes: Array(7).fill("Closed"),
          overview: '',
          services: '',
          type: '',
          email: '',
          phone: '',
          website: '',
        });
        setMode('');
        setResourceId('');
      } catch (error) {
        console.error("Error deleting resource: ", error);
        alert("Failed to delete resource. Please try again.");
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Resource Management
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please select whether you'd like to add a new resource or edit an existing one.
        </Typography>

        <FormControl fullWidth margin="normal">
          <Select 
            value={mode} 
            onChange={handleModeChange} 
            displayEmpty
            renderValue={() => {
              if (mode === "") {
                return <Typography>Select Mode</Typography>;
              }
              if(mode === 'add'){
                return <Typography>Add New Resource</Typography>
              }
              return <Typography>Edit Existing Resource</Typography>
            }}
          >
            <MenuItem value="add">Add New Resource</MenuItem>
            <MenuItem value="edit">Edit Existing Resource</MenuItem>
          </Select>
        </FormControl>

        {mode === 'edit' && (
          <FormControl fullWidth margin="normal">
            <Select 
              value={resourceId} 
              onChange={(e) => setResourceId(e.target.value)} 
              disabled={!resources.length}
              displayEmpty
              renderValue={() => {
                if (resourceId === "") {
                  return <Typography>Select Resource to Edit</Typography>;
                }
                return formData.name;
              }}
            >
              {resources.map((resource) => (
                <MenuItem key={resource.id} value={resource.id}>{resource.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {mode && (
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="name"
              value={formData.name}
              onChange={handleChange}
              label="Resource Name"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 50,
                  },
                },
              }}
              helperText={`${formData.name.length}/50 characters`}
              required
            />
            <TextField
              name="address"
              value={formData.address}
              onChange={handleChange}
              label="Address"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.address.length}/100 characters`}
              required
            />
            <TextField
              label="Phone"
              value={formData.phone}
              onChange={(e) => {
                const phoneValue = e.target.value;
                setFormData({ ...formData, phone: phoneValue });
                if (phoneValue === '') {
                  setPhoneError(''); // Clear error if empty
                } else {
                  const phoneRegex = /^[0-9]+$/;
                  if (!phoneRegex.test(phoneValue)) {
                    setPhoneError('Invalid phone number format');
                  } else {
                    setPhoneError('');
                  }
                }
              }}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 15,
                  },
                },
              }}
              helperText={phoneError || `${formData.phone.length}/15 characters`}
              error={!!phoneError}
            />
            <TextField
              label="Email"
              value={formData.email}
              onChange={(e) => {
                const emailValue = e.target.value;
                setFormData({ ...formData, email: emailValue });
                if (emailValue === '') {
                  setEmailError(''); // Clear error if empty
                } else {
                  // eslint-disable-next-line no-useless-escape
                  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (!emailRegex.test(emailValue)) {
                    setEmailError('Invalid email format');
                  } else {
                    setEmailError('');
                  }
                }
              }}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 50,
                  },
                },
              }}
              helperText={emailError || `${formData.email.length}/100 characters`}
              error={!!emailError}
            />
            <TextField
              name="website"
              value={formData.website}
              onChange={handleChange}
              label="Website Link"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.website.length}/100 characters`}
              />
            <TextField
              name="overview"
              value={formData.overview}
              onChange={handleChange}
              label="Overview"
              multiline
              rows={4}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.overview.length}/500 characters`}
              required
            />
            <TextField
              name="services"
              value={formData.services}
              onChange={handleChange}
              label="Services Offered"
              multiline
              rows={4}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              }}
              helperText={`${formData.services.length}/500 characters`}
              required
            />
            
            <FormControl fullWidth margin="normal">
              <Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                displayEmpty
                renderValue={() => {
                  if (formData.type === "") {
                    return <Typography>Resource Type</Typography>;
                  }
                  return <Typography>{formData.type}</Typography>;
                }}
              >
                {types.map((type, index) => (
                  <MenuItem key={index} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {daysOfWeek.map((day, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.openTimes[index] === "Closed"}
                      onChange={(e) => handleClosedChange(index, e.target.checked)}
                    />
                  }
                  label="Closed"
                />
                <TextField
                  fullWidth
                  label={`Start Time for ${day}`}
                  type="time"
                  value={formData.openTimes[index] === "Closed" ? "" : parseTime(formData.openTimes[index].split(' - ')[0])}
                  onChange={(e) => handleOpenTimesChange(index, 'start', e.target.value)}
                  inputProps={{ step: 900 }}
                  InputLabelProps={{ shrink: true }}
                  required
                  disabled={formData.openTimes[index] === "Closed"}
                />
                <TextField
                  fullWidth
                  label={`End Time for ${day}`}
                  type="time"
                  value={formData.openTimes[index] === "Closed" ? "" : parseTime(formData.openTimes[index].split(' - ')[1])}
                  onChange={(e) => handleOpenTimesChange(index, 'end', e.target.value)}
                  inputProps={{ step: 900 }}
                  InputLabelProps={{ shrink: true }}
                  required
                  disabled={formData.openTimes[index] === "Closed"}
                />
              </Box>
            ))}
                <Button type="submit" variant="contained">
    {mode === 'edit' ? "Save Changes" : "Add Resource"}
  </Button>

  {/* Show Edit Button only if mode is "edit" and a resource is selected */}
  {mode === 'edit' && resourceId && (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => setMode('edit')} // Placeholder action (can be customized)
      sx={{ marginTop: 2 }}
    >
      Edit Resource
    </Button>
  )}

  {/* Show Delete Button only if mode is "edit" and a resource is selected */}
  {mode === 'edit' && resourceId && (
    <Button
      variant="outlined"
      color="error"
      onClick={handleDelete}
      sx={{ marginTop: 2 }}
    >
      Delete Resource
    </Button>
  )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ResourceForm;
