import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid2, } from '@mui/material';
import { isDesktop } from 'react-device-detect';

function SpecificResourcePage(props) {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [goalHeight, setGoalHeight] = useState();
    const [bigger, setBigger] = useState();
    const [height, setHeight] = useState(0);
    const [test, setTest] = useState(true);
    const hours = useRef(null);
    const overviewBox = useRef(null);
    const map = useRef(null);
    const [mapWidth, setMapWidth] = useState(0);
    const [phone, setPhone] = useState();
    const [website, setWebsite] = useState();
    const [email, setEmail] = useState();



    useEffect(() => {
      console.log(map)
      console.log(map.current)
      if(map !== null && map.current !== null){ 
       if (mapWidth === 0 && map.current) {
            setMapWidth(map.current.offsetWidth);
        }
        if (hours.current && overviewBox.current && height === 0) {
            setGoalHeight(hours.current.offsetHeight);
            setHeight(overviewBox.current.offsetHeight);
        }
        if (height < goalHeight) {
            setBigger(true);
        }
        setTest(false);
        setEmail(props.resource.email !== undefined);
        setPhone(props.resource.phone !== undefined);
        setWebsite(props.resource.website !== undefined);
        window.addEventListener('resize', () => setMapWidth(map.current.offsetWidth));
      }
    }, [mapWidth, height, goalHeight, bigger, test, props.resource, email, phone]);





    if (isDesktop) {
        return (
            <Box>
                {test && <Box ref={overviewBox} sx={{ display: "flex", flexDirection: "column", paddingTop: "4vh", paddingBottom: "4vh", maxWidth: "50%" }}>
                    <Typography variant='h4' backgroundColor="#D6F1FF" paddingTop="5vh" paddingRight="5vw" paddingLeft="5vw" >Overview</Typography>
                    <Typography variant='h5' backgroundColor="#D6F1FF" paddingTop="2vh" width="45vw" paddingRight="5vw" paddingLeft="5vw" paddingBottom="5vh">{props.resource.overview}</Typography>
                </Box>}
                <Box backgroundColor='#D6F1FF' textAlign='center' paddingTop="5vh" paddingBottom="5vh" maxWidth=".96" marginLeft="1.5%" borderRadius="10px">
                    <Typography variant='h2'>{props.resource.name}</Typography>
                </Box>
                <Grid2 container sx={{ justifyContent: "space-evenly" }} >
                    <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "4vh", paddingBottom: "4vh", maxWidth: "50%" }}>
                        <Typography variant='h4' backgroundColor="#D6F1FF" paddingTop="5vh" paddingRight="2.5svw" paddingLeft="2.5vw" sx={{borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>Overview</Typography>
                        <Typography variant='h5' backgroundColor="#D6F1FF" paddingTop="2vh" width="45vw" paddingRight="2.5vw" paddingLeft="2.5vw" paddingBottom={bigger ? (goalHeight - height + (5 / 100 * window.innerHeight)).toString().concat("px") : "5vh"} sx={{borderBottomLeftRadius:"10px", borderBottomRightRadius:"10px"}}>{props.resource.overview}</Typography>
                    </Box>
                    <Box paddingTop="4vh" paddingBottom="4vh" ref={hours} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h4" paddingTop="5vh" paddingRight="2.5vw" paddingLeft="2.5vw" backgroundColor="#D6F1FF" sx={{borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>
                            Hours
                        </Typography>
                        {Array.from(Array(7), (e, i) => (
                            <Typography variant="h5" paddingTop="2vh" position="relative" paddingRight="2.5vw" paddingLeft="2.5vw" backgroundColor="#D6F1FF" paddingBottom={(i === 6) ? "5vh" : ("0")} sx={{borderBottomLeftRadius:i === 6 ? "10px": "0px", borderBottomRightRadius:i=== 6 ? "10px" : "0px"}}>
                                {daysOfWeek[i].concat(": ").concat(props.resource.openTimes[i])}
                            </Typography>
                        ))}
                    </Box>
                </Grid2>
                <Grid2 container sx={{ justifyContent: "center" }}>
                    <Box ref={map} sx={{ textAlign: "center", paddingLeft: "3vw", paddingRight: "3vw", paddingBottom: "6vh", paddingTop: "3vh", backgroundColor: "#D6F1FF", width: "80vw", borderTopLeftRadius:"10px", borderTopRightRadius:"10px", borderBottomRightRadius:"10px", borderBottomLeftRadius:"10px"}}>
                        <Typography variant="h4" >
                            Address
                        </Typography>
                        <Typography paddingBottom="2vh">
                            {props.resource.address}
                        </Typography>
                        {map !== null && map.current !== null && 
                          <iframe
                            style={{ position: "inline", padding: "2vh" }}
                            title="map"
                            width="96%"
                            height="600"
                            frameBorder="0"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAZg61q1ejUnk97tQt6ZJcfkxoTmJ794As&q=${encodeURIComponent(props.resource.address)}`}
                            allowFullScreen>
                          </iframe>
                        }
                    </Box>
                </Grid2>
                <Grid2 container justifyContent="center">
                    <Box sx={{ paddingTop: "5vh", paddingBottom: "5vh" }}>
                        <Typography variant="h4" backgroundColor="#D6F1FF" position="inline" paddingTop="3vh" paddingBottom="2vh" paddingLeft="2.5vh" width={mapWidth} sx={{borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>Services</Typography>
                        <Typography variant="h5" backgroundColor="#D6F1FF" position="inline" width={mapWidth} paddingBottom="3vh" paddingLeft="2.5vh" paddingRight="2.5vh" sx={{borderBottomRightRadius:"10px", borderBottomLeftRadius:"10px"}}>{props.resource.services}</Typography>
                    </Box>
                </Grid2>
                <Grid2 container justifyContent="center">
                    <Box sx={{ paddingBottom: "5vh" }}>
                        {(email || phone || website) && <Typography variant="h4" backgroundColor="#D6F1FF" position="inline" paddingTop="3vh" paddingBottom="2vh" paddingLeft="2.5vh" width={mapWidth} sx={{borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>Contact</Typography>}
                        {email && <Typography variant="h5" backgroundColor="#D6F1FF" position="inline" width={mapWidth} paddingBottom="3vh" paddingLeft="2.5vh" paddingRight="2.5vh" sx={(phone || website) ? "" : {borderBottomRightRadius:"10px", borderBottomLeftRadius:"10px"}}>{"Email: ".concat(props.resource.email)}</Typography>}
                        {phone && <Typography variant="h5" backgroundColor="#D6F1FF" position="inline" width={mapWidth} paddingBottom="3vh" paddingLeft="2.5vh" paddingRight="2.5vh" sx={(website) ? "" : {borderBottomRightRadius:"10px", borderBottomLeftRadius:"10px"}}>{"Phone Number: ".concat(props.resource.phone)}</Typography>}
                        {website && 
                          <div style={{display: "flex", width: mapWidth, borderBottomRightRadius:"10px", borderBottomLeftRadius:"10px", backgroundColor: "#D6F1FF" }}>
                            <Typography variant="h5"position="inline" paddingBottom="3vh" paddingLeft="2.5vh">Website: </Typography>
                            <Typography variant="h5"position="inline" paddingBottom="3vh" paddingLeft="1vh" paddingRight="2.5vh" component="a" href={`https://www.${props.resource.website}`}>{props.resource.website}</Typography>
                          </div>
                        }
                    </Box>
                </Grid2>
            </Box>
        )
    }
    else {
        return (
            <Box alignText="center">
                <Box backgroundColor='#53C9ED' textAlign='center' paddingTop="5vh" paddingBottom="5vh" maxWidth=".96" marginLeft="1.5%" borderRadius="10px">
                    <Typography variant='h2' paddingRight="5vw" paddingLeft="5vw">{props.resource.name}</Typography>
                </Box>
                <Box sx={{ paddingTop: "5vh", paddingBottom: "5vh", maxWidth: "50%", display: "inline", position: "relative", textAlign: "center" }}>
                    <Typography variant="h4" sx={{ paddingLeft: "5vw", paddingRight: "5vw", paddingTop: "5vh" }}>Overview</Typography>
                    <Typography variant='h5' sx={{ paddingLeft: "5vw", paddingRight: "5vw" }}>{props.resource.overview}</Typography>
                </Box>
                <Box sx={{
                    paddingTop: "5vh", paddingBottom: "5vh", display: "inline", position: "relative", maxWidth: "25%", marginLeft: "7%", textAlign: "center"
                }}>
                    <Typography variant="h4">
                        Hours
                    </Typography>
                    {Array.from(Array(7), (e, i) => (
                        <Typography variant="h5" marginTop="2%" position="relative">
                            {daysOfWeek[i].concat(": ").concat(props.resource.openTimes[i])}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ textAlign: "center", paddingTop: "5vh" }}>
                    <Typography variant="h4">
                        Address
                    </Typography>
                    <Typography>
                        {props.resource.address}
                    </Typography>
                    <iframe
                          style={{
                              position: "inline", padding: "2vh"
                          }}
                          title="map"
                          width="96%"
                          height="600"
                          frameborder="0"
                          referrerpolicy="no-referrer-when-downgrade"
                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAZg61q1ejUnk97tQt6ZJcfkxoTmJ794As&q=${encodeURIComponent(props.resource.address)}`}
                          allowfullscreen>
                    </iframe>
                </Box>
                <Box sx={{ paddingTop: "5vh", position: "relative", paddingBottom: "5vh", textAlign: "center" }}>
                    <Typography variant="h4">Services</Typography>
                    <Typography variant="h5">{props.resource.services}</Typography>
                </Box>
            </Box>
        )
    }
}
//get rid of description
//increase font size for services and what to expect
//change fonts
//align the top intro box
export default SpecificResourcePage;