import React from 'react';
import { Box, Typography, Container, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import image from '../images/lthcHQ.jpeg';

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: 'calc(100vh - 64px)',
  width: '100%',
  padding: '0 16px',
  gap: '24px',
});

const HomePage = () => {
  return (
    <CenteredContainer maxWidth="lg">
      <Box textAlign="center" width="100%">
        <img src={image} alt="LTHC HQ" style={{ width: '100%', height: 'auto', marginBottom: '24px' }} />
        <Typography fontWeight="bold" variant="h3" gutterBottom>
          Welcome!
        </Typography>
        <Button
          component={Link}
          to='/resourcestab/resources'
          sx={{
            backgroundColor: '#ecb81c',
            color: 'black',
            borderRadius: '20px',
            padding: '20px 30px',
            marginBottom: '75px', 
            underline: 'black',
            fontSize: '22px',
            fontWeight: 'bold',
            textTransform: "none"
          }}
        >
          Discover Resources!
        </Button>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          Services
        </Typography>
      </Box>
      <Grid container spacing={3} width="100%">
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Housing
            </Typography>
            <Typography>
            We provide a safe place to stay if you need housing right away, and we also offer programs to help you stay longer while you work toward finding a permanent home.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Food
            </Typography>
            <Typography>
            We have several options for meal assistance such as food pantries and soup kitchens where you can get groceries and other important items. The LTHC community kitchen and other spots in town serve hot meals every day. Culinary programs are also available to learn simple cooking skills.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Financial
            </Typography>
            <Typography>
            We can help you manage your money and build important skills, like planning a budget, setting up a bank account, understanding credit, and handling debt. This support can make it easier to reach your financial goals.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Medical
            </Typography>
            <Typography>
            We’re here to support your health—both body and mind. Whether you need regular checkups, help with an illness, or someone to talk to when you’re feeling sad or anxious, we can connect you with the right care. We’re also here to help if you’re working through challenges with substance use and addiction.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </CenteredContainer>
  );
};

export default HomePage;
